/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18/11/2022.
 */
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import MobileNavigation from '../components/MobileNavigation'
import { CONTACT } from '../constant'

const Collaborators = (props) => {
  return (
    <Layout>
      <SEO title="Studies&me" />

      <div className="container terms ">
        <div className="row" style={{}}>
          <div className="col-10 col-md-8 mx-auto mt-6">
            <h2 className="mb-6 mt-6">Who does Studies&Me collaborate with?</h2>
            <p style={{ hyphens: 'none' }}>
              Studies&Me recruits participants for clinical studies in collaboration with pharmaceutical companies or
              Contract Research Organizations (CROs). CROs conduct clinical studies on behalf of clients from the
              pharmaceutical, biotech and medical device industry.
            </p>
            <h4 style={{ fontWeight: 400 }}>
              Below, you can find a complete list of Studies&Me’s current and previous collaboration partners.
            </h4>
            <ul>
              <li>
                <a href="https://www.nbcd.com/" className="border-bottom">
                  <p>NBCD</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row" style={{}}>
          <div className="col-12" style={{ height: '5rem' }}></div>
        </div>
      </div>
    </Layout>
  )
}

export default Collaborators
